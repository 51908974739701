document.addEventListener('DOMContentLoaded', () =>
{

    const headerMenubar = document.getElementById('j-header-menubar');
    if (headerMenubar)
    {

        headerMenubar.addEventListener('click', () =>
        {

            const html = document.querySelector('html');
            const j_menu_area = document.getElementById('j-menu-area');
            const j_aside_area = document.getElementById('j-aside-area');
            const j_aside_content = document.getElementById('j-aside-content');

            if (html && j_menu_area && j_aside_area && j_aside_content)
            {

                html.classList.toggle('u-overflow--hidden');
                j_aside_area.classList.toggle('u-display--none');

                if (window.getComputedStyle(j_aside_content).getPropertyValue('justify-content') === 'flex-start')
                {

                    j_menu_area.classList.remove('u-animation__menu_left--to-left');
                    j_menu_area.classList.add('u-animation__menu_left--to-right');

                }
                else
                {

                    j_menu_area.classList.remove('u-animation__menu_right--to-right');
                    j_menu_area.classList.add('u-animation__menu_right--to-left');

                }

            }

        });

    }

    const asideClose = document.getElementById('j-aside-close');
    if (asideClose)
    {

        asideClose.addEventListener('click', () =>
        {

            const html = document.querySelector('html');
            const j_menu_area = document.getElementById('j-menu-area');
            const j_aside_area = document.getElementById('j-aside-area');
            const j_aside_content = document.getElementById('j-aside-content');

            if (html && j_menu_area && j_aside_area && j_aside_content)
            {

                html.classList.toggle('u-overflow--hidden');

                if (window.getComputedStyle(j_aside_content).getPropertyValue('justify-content') === 'flex-start')
                {

                    j_menu_area.classList.remove('u-animation__menu_left--to-right');
                    j_menu_area.classList.add('u-animation__menu_left--to-left');

                }
                else
                {

                    j_menu_area.classList.remove('u-animation__menu_right--to-left');
                    j_menu_area.classList.add('u-animation__menu_right--to-right');

                }

                setTimeout(() =>
                {
                    j_aside_area.classList.toggle('u-display--none');
                }, 400);

            }

        });

    }

    const topBanner = document.getElementById('j-single-top-banner') as HTMLParagraphElement;
    const bottomBanner = document.getElementById('j-single-bottom-banner') as HTMLParagraphElement;
    if (topBanner && bottomBanner)
    {

      const j_aside_content = document.getElementById('j-aside-content');
      if (j_aside_content)
      {

        if (window.getComputedStyle(j_aside_content).getPropertyValue('justify-content') === 'flex-start')
        {

          window.addEventListener('scroll', () =>
          {

              if (window.scrollY > 180)
              {

                topBanner.classList.add('u-position--absolute', 'u-position__left--0');
                topBanner.style.top = `${window.scrollY}px`;

                if (bottomBanner.getBoundingClientRect().top < 600)
                {

                  topBanner.style.top = 'auto';
                  topBanner.style.bottom = '0';

                }

              }
              else
              {

                topBanner.classList.remove('u-position--absolute', 'u-position__left--0');
                topBanner.style.top = '0';

              }

          });

        }

      }

    }

});

const moveHeader = () =>
{

    const header = document.getElementById('j-header') as HTMLDivElement;
    window.addEventListener('scroll', () =>
    {

        if (window.scrollY > 180)
        {

            header.classList.add('u-position--sticky', 'u-position__inset--0', 'u-border__bottom_color--white-gray', 'u-border__bottom_width--0_0625rem', 'u-border__bottom_style--solid', 'u-z-index--5');

        }
        else
        {

            header.classList.remove('u-position--sticky', 'u-position__inset--0', 'u-border__bottom_color--white-gray', 'u-border__bottom_width--0_0625rem', 'u-border__bottom_style--solid', 'u-z-index--5');

        }

    });

}
moveHeader();
